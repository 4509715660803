/**
 * This should not be a constant but since we're still in testing stages
 * these are subject to change (or just test URLs)
 */
export const AGL_DOMAINS = ['agl-gamma.aka.amazon.com', 'agl.amazon.com'];
export const MONS_DOMAINS = [
  // CBMS-owned test stages
  'alpha.cbms.agl.amazon.dev',
  'beta.cbms.agl.amazon.dev',
  'gamma.cbms.agl.amazon.dev',
  'cbms.agl.amazon.dev',
  ...AGL_DOMAINS,
];

export const isMonsEnv = (): boolean => {
  const domain = window.location.hostname;
  return MONS_DOMAINS.includes(domain);
};

export const isAglPortal = (): boolean => {
  const domain = window.location.hostname;
  return AGL_DOMAINS.includes(domain);
};
