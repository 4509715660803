import { ChargeCode } from '@xbcb/finance-types';
import { EsDataType, expirationTime } from '../..';
import { createTableConfig } from '../createTableConfig';

const columns = {
  expirationTime,
  serviceType: {
    default: true,
    name: 'Service Type',
    type: EsDataType.KEYWORD,
  },
  payeeId: {
    default: true,
    name: 'Payee Id',
    type: EsDataType.KEYWORD,
  },
  workOrderGroupId: {
    default: true,
    name: 'Work Order Group Id',
    type: EsDataType.KEYWORD,
  },
  status: {
    default: true,
    name: 'Invoice Status',
    type: EsDataType.KEYWORD,
  },
  externalReferenceId: {
    default: true,
    name: 'External Ref Id',
    type: EsDataType.KEYWORD,
  },
  chargeCode: {
    default: true,
    name: 'Charge Code',
    type: EsDataType.KEYWORD,
    optionValues: Object.values(ChargeCode),
  },
};

export const payableInvoice = createTableConfig({
  columns,
  tableOptions: {
    sortField: 'status',
  },
});
