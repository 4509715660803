import { ObjectType, AccountType } from '@xbcb/shared-types';
import { camelCase } from 'lodash';
import { permissionDependency } from './permissionDependency';
import { getUserAccountType } from './getUserAccountType';
import { PermissionsByAccountType } from '@xbcb/client-types';
import { assumePermission } from './assumePermission';

// TODO: consolidate the types, since some of these definitions also exist in XbcbClientAuthority/packages/lambda/src/api/permission/
// please add to this whenever a new user permission is introduced!
enum UserCrudPermissions {
  READ = 'READ',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

enum UserSupplierTruckerPermissions {
  UPSERT_CBP_MID_TRANSACTION = 'UPSERT_CBP_MID_TRANSACTION',
}

enum UserUsConsigneePermissions {
  CREATE_US_CBP_5106_TRANSACTION = 'CREATE_US_CBP_5106_TRANSACTION',
  UPDATE_US_CBP_5106_TRANSACTION = 'UPDATE_US_CBP_5106_TRANSACTION',
  QUERY_US_CBP_TRANSACTION = 'QUERY_US_CBP_TRANSACTION',
}

enum UserUsIorPermissions {
  CREATE_CBP_5106_TRANSACTION = 'CREATE_CBP_5106_TRANSACTION',
  UPDATE_CBP_5106_TRANSACTION = 'UPDATE_CBP_5106_TRANSACTION',
  QUERY_CBP_TRANSACTION = 'QUERY_CBP_TRANSACTION',
  REQUEST_CBP_ASSIGNED_NUMBER_TRANSACTION = 'REQUEST_CBP_ASSIGNED_NUMBER_TRANSACTION',
}

enum UserUsConsumptionEntryPermissions {
  UPSERT_CBP_TRANSACTION = 'UPSERT_CBP_TRANSACTION',
  DELETE_CBP_TRANSACTION = 'DELETE_CBP_TRANSACTION',
  QUERY_CBP_TRANSACTION = 'QUERY_CBP_TRANSACTION',
  UPDATE_RELEASE_CBP_TRANSACTION = 'UPDATE_RELEASE_CBP_TRANSACTION',
  REPLACE_RELEASE_CBP_TRANSACTION = 'REPLACE_RELEASE_CBP_TRANSACTION',
  DELETE_RELEASE_CBP_TRANSACTION = 'DELETE_RELEASE_CBP_TRANSACTION',
  UPDATE_PGA_CBP_TRANSACTION = 'UPDATE_PGA_CBP_TRANSACTION',
  AUTHORIZE_STATEMENT_CBP_TRANSACTION = 'AUTHORIZE_STATEMENT_CBP_TRANSACTION',
  UPDATE_STATEMENT_CBP_TRANSACTION = 'UPDATE_STATEMENT_CBP_TRANSACTION',
}

enum UserUsType86EntryPermissions {
  CREATE_RELEASE_CBP_TRANSACTION = 'CREATE_RELEASE_CBP_TRANSACTION',
  UPDATE_RELEASE_CBP_TRANSACTION = 'UPDATE_RELEASE_CBP_TRANSACTION',
  DELETE_RELEASE_CBP_TRANSACTION = 'DELETE_RELEASE_CBP_TRANSACTION',
  QUERY_RELEASE_CBP_TRANSACTION = 'QUERY_RELEASE_CBP_TRANSACTION',
  UPDATE_PGA_CBP_TRANSACTION = 'UPDATE_PGA_CBP_TRANSACTION',
}

enum UserUsPostSummaryCorrectionPermissions {
  UPSERT_CBP_TRANSACTION = 'UPSERT_POST_SUMMARY_CORRECTION_CBP_TRANSACTION',
}

enum UserUsIsfPermissions {
  CREATE_CBP_TRANSACTION = 'CREATE_CBP_TRANSACTION',
  UPDATE_CBP_TRANSACTION = 'UPDATE_CBP_TRANSACTION',
  DELETE_CBP_TRANSACTION = 'DELETE_CBP_TRANSACTION',
}

enum UsInBondPermissionName {
  IN_BOND_SUBMIT_CBP_TRANSACTION = 'IN_BOND_SUBMIT_CBP_TRANSACTION',
  IN_BOND_DELETE_CBP_TRANSACTION = 'IN_BOND_DELETE_CBP_TRANSACTION',
  IN_BOND_SUBMIT_ACTION_CBP_TRANSACTION = 'IN_BOND_SUBMIT_ACTION_CBP_TRANSACTION',
  IN_BOND_DELETE_BILL_CBP_TRANSACTION = 'IN_BOND_DELETE_BILL_CBP_TRANSACTION',
}

export const UserPermissions = {
  ...UserCrudPermissions,
  ...UserSupplierTruckerPermissions,
  ...UserUsConsigneePermissions,
  ...UserUsIorPermissions,
  ...UserUsConsumptionEntryPermissions,
  ...UserUsIsfPermissions,
  ...UserUsType86EntryPermissions,
};

// used by custom react hook usePermissions
export const permissionsByObjectType: {
  [key in keyof typeof ObjectType]?: { [key: string]: string };
} = {
  [ObjectType.DOCUMENT]: { ...UserCrudPermissions },
  [ObjectType.DOCUMENT_TEMPLATE]: { ...UserCrudPermissions },
  [ObjectType.CUSTOMS_AGENT]: { ...UserCrudPermissions },
  [ObjectType.FACILITY]: { ...UserCrudPermissions },
  [ObjectType.FORWARDER]: { ...UserCrudPermissions },
  [ObjectType.SHIPPER]: { ...UserCrudPermissions },
  [ObjectType.SUPPLIER]: {
    ...UserCrudPermissions,
    ...UserSupplierTruckerPermissions,
  },
  [ObjectType.TRUCKER]: {
    ...UserCrudPermissions,
    ...UserSupplierTruckerPermissions,
  },
  [ObjectType.US_CONSIGNEE]: {
    ...UserCrudPermissions,
    ...UserUsConsigneePermissions,
  },
  [ObjectType.US_IOR]: { ...UserCrudPermissions, ...UserUsIorPermissions },
  [ObjectType.GB_IOR]: { ...UserCrudPermissions },
  [ObjectType.DE_IOR]: { ...UserCrudPermissions },
  [ObjectType.NL_IOR]: { ...UserCrudPermissions },
  [ObjectType.FR_IOR]: { ...UserCrudPermissions },
  [ObjectType.US_CONSUMPTION_ENTRY]: {
    ...UserCrudPermissions,
    ...UserUsConsumptionEntryPermissions,
  },
  [ObjectType.US_TYPE86_ENTRY]: {
    ...UserCrudPermissions,
    ...UserUsType86EntryPermissions,
  },
  [ObjectType.US_POST_SUMMARY_CORRECTION]: {
    ...UserCrudPermissions,
    ...UserUsPostSummaryCorrectionPermissions,
  },
  [ObjectType.US_ISF]: { ...UserCrudPermissions, ...UserUsIsfPermissions },
  [ObjectType.INVOICE]: { ...UserCrudPermissions },
  [ObjectType.PRODUCT]: { ...UserCrudPermissions },
  [ObjectType.SHIPMENT]: { ...UserCrudPermissions },
  [ObjectType.WORK_ORDER_TASK]: { ...UserCrudPermissions },
  [ObjectType.USER]: { ...UserCrudPermissions },
  [ObjectType.OPERATOR_USER]: { ...UserCrudPermissions },
  [ObjectType.FORWARDER_USER]: { ...UserCrudPermissions },
  [ObjectType.SHIPPER_USER]: { ...UserCrudPermissions },
  [ObjectType.US_IOR_CONTINUOUS_BOND_REQUEST]: { ...UserCrudPermissions },
  [ObjectType.REPORT_RECONCILIATION_REQUEST]: { ...UserCrudPermissions },
  [ObjectType.BULK_MILESTONE_UPLOAD_REQUEST]: { ...UserCrudPermissions },
  [ObjectType.BULK_CHARGES_UPLOAD_REQUEST]: { ...UserCrudPermissions },
  [ObjectType.US_IN_BOND]: {
    ...UserCrudPermissions,
    ...UsInBondPermissionName,
  },
  [ObjectType.DELIVERY_ORDER]: { ...UserCrudPermissions },
  [ObjectType.US_IOR_ACTIVATION]: { ...UserCrudPermissions },
  [ObjectType.GB_IOR_ACTIVATION]: { ...UserCrudPermissions },
  [ObjectType.DE_IOR_ACTIVATION]: { ...UserCrudPermissions },
  [ObjectType.NL_IOR_ACTIVATION]: { ...UserCrudPermissions },
  [ObjectType.FR_IOR_ACTIVATION]: { ...UserCrudPermissions },
  [ObjectType.ASSIGNMENT_TEAM]: { ...UserCrudPermissions },
  [ObjectType.BUSINESS_SUPPORT_TEAM]: { ...UserCrudPermissions },
  [ObjectType.SUBJECT_MATTER_EXPERT_TEAM]: { ...UserCrudPermissions },
  [ObjectType.GB_CUSTOMS_ENTRY]: { ...UserCrudPermissions },
  [ObjectType.DE_CUSTOMS_ENTRY]: { ...UserCrudPermissions },
  [ObjectType.NL_CUSTOMS_ENTRY]: { ...UserCrudPermissions },
  [ObjectType.FR_CUSTOMS_ENTRY]: { ...UserCrudPermissions },
  [ObjectType.WORK_ORDER_TASK_ESCALATION_MESSAGE]: { ...UserCrudPermissions },
  [ObjectType.EXCHANGE_MESSAGE]: { ...UserCrudPermissions },
  [ObjectType.GLOBAL_CONSIGNEE]: { ...UserCrudPermissions },
};

const ADMIN = 'ADMIN';
const READ_ONLY = 'READ_ONLY';
const EXPLICIT_READ = 'EXPLICIT_READ'; // currently being used for sider menu

type PermissionInterface = {
  name: string;
};

/**
 * Types of users to be authenticated.
 * NOTE: We don't want to load @xbcb/api-gateway-client onto the front-end
 */
// type UserType = OperatorUser | ForwarderUser | ShipperUser;

/**
 * Extracts the permissions of a user of type OperatorUser, ForwarderUser, or ShipperUser.
 * @param { any } user The user whose permissions we want to extract
 * @return { {} } The permissions object
 */
export const getPermissions = (user: any = {}) => {
  if ('shipperUserPermissions' in user) {
    return user['shipperUserPermissions'];
  } else if ('operatorUserPermissions' in user) {
    return user['operatorUserPermissions'];
  } else if ('forwarderUserPermissions' in user) {
    return user['forwarderUserPermissions'];
  } else {
    return user.permissions;
  }
};

/**
 * @param { TUser } user The user requesting access.
 * @param { ObjectType } objectType The record permission is requested for.
 * @param { string } permission The name of permission that needs to be authenticated.
 * @return { boolean } Whether the user is authenticated to access the record.
 */
export const checkAccess = (
  user: any = {},
  objectType: ObjectType,
  permission: string,
): boolean => {
  const accountType = getUserAccountType(user.id);
  objectType = assumePermission[objectType] ?? objectType;

  const recordName = getRecordNameKey({ objectType, accountType });
  const userPermissions = getPermissions(user);

  const permissionsOnRecord = userPermissions
    ? userPermissions[recordName as keyof typeof userPermissions]
    : undefined;

  // if the user has explicitly no_access then even root doesn't allow access
  if (hasPermission(permissionsOnRecord, 'NO_ACCESS')) {
    return false;
  }

  if (user.root) {
    // The root user has access to everything that can be read
    if (
      accountType &&
      !Object.keys(PermissionsByAccountType[accountType]).includes(objectType)
    ) {
      return false;
    }
    return true;
  }

  // The user does not have any permission configured
  if (userPermissions === undefined || userPermissions === null) {
    return false;
  }

  if (permission === READ_ONLY) {
    if (
      hasReadOnlyPermission(permissionsOnRecord, userPermissions, recordName)
    ) {
      return true;
    } else {
      return false;
    }
  }

  // If the user has ADMIN access to the record, allow all the access.
  if (hasPermission(permissionsOnRecord, ADMIN)) {
    return true;
  }

  // Check whether the user has EXPLICIT_READ (i.e. not including indirect and implicit read) access to record type
  if (permission === EXPLICIT_READ) {
    if (hasPermission(permissionsOnRecord, UserPermissions.READ)) {
      return true;
    } else {
      return false;
    }
  }

  // Check the user's permission includes requested permission.
  if (hasPermission(permissionsOnRecord, permission)) {
    return true;
  }
  // Check if the user has implicit read access or read access that can be inherited from parent record type.
  if (
    permission === UserPermissions.READ &&
    (hasIndirectReadAccess(userPermissions, recordName) ||
      hasImplicitReadPermission(permissionsOnRecord))
  ) {
    return true;
  }

  return false;
};

// check if the user has indirect read access to record due to being authenticated with the parents record type.
const hasIndirectReadAccess = (userPermissions: any, objectType: string) => {
  return Object.keys(permissionDependency).some((parentObjectType) => {
    return permissionDependency[
      parentObjectType as keyof typeof permissionDependency
    ].some((dependentObjectType) => {
      // return true if the user is authenticated(read or admin) with any of the parent record types
      return (
        dependentObjectType === objectType &&
        userPermissions[parentObjectType] &&
        (hasPermission(userPermissions[parentObjectType], ADMIN) ||
          hasPermission(
            userPermissions[parentObjectType],
            UserPermissions.READ,
          ))
      );
    });
  });
};

/**
 * Return true if user has at least one permission on record type of interest.
 * @param { PermissionInterface[] } permissionsList User's list of permissions on a particular record type.
 * @return { boolean } true or false
 */
const hasImplicitReadPermission = (permissionsList: PermissionInterface[]) => {
  return permissionsList?.length;
};

/**
 * Returns true if user has either direct or indirect READ_ONLY permission
 * for a record type.
 * NOTE: This method is currently unused by other packages and apps, but it
 * may come in handy in the future.
 * @param { PermissionInterface[] } permissionsList User's permissions on record type
 * @param { any } userPermissions User's permissions on all record types
 * @param { string } recordName
 * @return { boolean } true or false
 */
const hasReadOnlyPermission = (
  permissionsList: PermissionInterface[],
  userPermissions: any,
  recordName: string,
) => {
  const hasDirectReadOnly =
    permissionsList?.length === 1 &&
    permissionsList[0].name === UserPermissions.READ;
  const hasIndirectReadOnly =
    !permissionsList?.length &&
    hasIndirectReadAccess(userPermissions, recordName);
  return hasDirectReadOnly || hasIndirectReadOnly;
};

// check if given permission list includes requested permission
// this is required since TS does not support .includes() for object types
const hasPermission = (
  permissionList: PermissionInterface[],
  permission: string,
) => {
  return (
    permissionList && permissionList.some((perm) => perm.name === permission)
  );
};
// user's permission record key name sometimes are different from record name itself
const getRecordNameKey = (props: {
  objectType: ObjectType;
  accountType?: AccountType;
}) => {
  const { objectType, accountType } = props;

  // users permission to its own company is defined as 'company'
  // ex. forwarderUser.permissions.company => forwarderUser's permission to Forwarder
  if (
    [ObjectType.FORWARDER, ObjectType.SHIPPER, ObjectType.OPERATOR].includes(
      objectType as any,
    ) &&
    accountType === (objectType as any)
  ) {
    return 'company';
  }

  return camelCase(objectType);
};
