/**
 * Represents the type of document or message to be exhanged.
 */
export enum ExchangeMessageType {
  CATAIR = 'CATAIR',
  CAMIR = 'CAMIR',
  CHAIN_IO_JSON = 'CHAIN_IO_JSON',
  DIS = 'DIS',
  EMAIL = 'EMAIL',
  X12 = 'X12',
  WCO_DM = 'WCO_DM', // https://datamodel.wcoomd.org
}
