import { AccountType, SortOrder } from '@xbcb/shared-types';
import { createTableConfig } from './createTableConfig';

import { EsDataType } from '../index';

export const exchangeMessage = createTableConfig({
  columns: {
    from: {
      default: true,
      whitelist: [AccountType.OPERATOR],
      name: 'From',
      type: EsDataType.KEYWORD,
    },
    to: {
      default: true,
      whitelist: [AccountType.OPERATOR],
      name: 'To',
      type: EsDataType.KEYWORD,
    },
    subject: {
      default: true,
      whitelist: [AccountType.OPERATOR],
      name: 'Subject',
      type: EsDataType.KEYWORD,
    },
    preview: {
      default: true,
      whitelist: [AccountType.OPERATOR],
      name: 'Preview',
      type: EsDataType.KEYWORD,
    },
    workOrderId: {
      default: true,
      whitelist: [AccountType.OPERATOR],
      name: 'Work Order ID',
      type: EsDataType.KEYWORD,
    },
    sender: {
      default: true,
      whitelist: [AccountType.OPERATOR],
      name: 'Sender',
      type: EsDataType.KEYWORD,
    },
    receiver: {
      default: true,
      whitelist: [AccountType.OPERATOR],
      name: 'Receiver',
      type: EsDataType.KEYWORD,
    },
    type: {
      default: true,
      whitelist: [AccountType.OPERATOR],
      name: 'Document Type',
      type: EsDataType.KEYWORD,
    },
    status: {
      default: true,
      whitelist: [AccountType.OPERATOR],
      name: 'Status',
      type: EsDataType.KEYWORD,
    },
  },
  tableOptions: {
    sortOrder: SortOrder.DESC,
    sortField: 'createdTime',
  },
});
