import { AccountType, LoadType, ModeOfTransport } from '@xbcb/shared-types';
import { createTableConfig } from '../createTableConfig';
import { EsDataType } from '../../index';
import { sharedCancellationColumns } from '../sharedCancellationColums';
import {
  WorkOrderBadge,
  WorkOrderBlockedReasonCode,
  WorkOrderMilestoneName,
  WorkOrderStatus,
  WorkOrderTaskStatus,
  WorkOrderTaskSubStatus,
  WorkOrderTaskType,
  WorkOrderType,
} from '@xbcb/work-order-types';
import { iorNumber } from '../iorNumber';

export const workOrderTask = createTableConfig({
  tableOptions: {
    whitelist: [AccountType.OPERATOR],
    sortField: 'deadline',
    dependentFields: ['workOrderId', 'workOrderTaskType', 'workOrderType'],
  }, // TODO feature to be added at top level
  columns: {
    workOrderId: {
      // parent interface id
      name: 'Work Order Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    assigneeId: {
      name: 'Assignee Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    assigneeName: {
      name: 'Assignee Name',
      type: EsDataType.TEXT,
      default: true,
      // TODO add permission to only allow managers to view
    },
    deadline: {
      name: 'Task Deadline',
      type: EsDataType.TIME,
      default: true,
    },
    estimatedTimeOfArrival: {
      name: 'Estimated Time of Arrival',
      type: EsDataType.TIME,
      default: true,
    },
    forwarderName: {
      name: (accountType: AccountType) =>
        accountType === AccountType.FORWARDER ? 'Agent' : 'Forwarder',
      type: EsDataType.TEXT,
      default: false,
    },
    iorName: {
      name: 'Importer of Record',
      type: EsDataType.TEXT,
      default: true,
    },
    iorNumber,
    teamId: {
      name: 'Team Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    teamName: {
      name: 'Team Name',
      type: EsDataType.TEXT,
      default: true,
    },
    minimumSkillRequired: {
      name: 'Minimum Skill Required',
      type: EsDataType.KEYWORD,
      default: true,
    },
    name: {
      name: 'Task Name',
      type: EsDataType.TEXT,
      default: true,
    },
    status: {
      name: 'Task Status',
      type: EsDataType.KEYWORD,
      default: true,
      optionValues: Object.keys(WorkOrderTaskStatus),
    },
    subStatus: {
      name: 'Task Sub Status',
      type: EsDataType.KEYWORD,
      default: true,
      optionValues: Object.keys(WorkOrderTaskSubStatus),
    },
    workOrderType: {
      name: 'Work Order Type',
      type: EsDataType.KEYWORD,
      default: true,
      optionValues: Object.keys(WorkOrderType),
    },
    workOrderTaskType: {
      name: 'Work Order Task Type',
      type: EsDataType.KEYWORD,
      default: true,
      optionValues: Object.keys(WorkOrderTaskType),
    },
    workOrderStatus: {
      name: 'Work Order Status',
      type: EsDataType.KEYWORD,
      default: true,
      optionValues: Object.keys(WorkOrderStatus),
    },
    workOrderTaskDefinitionId: {
      hidden: true,
      name: 'Work Order Task Definition Id',
      type: EsDataType.KEYWORD,
    },
    latestMilestone: {
      name: 'Latest Milestone',
      type: EsDataType.KEYWORD,
      default: true,
      optionValues: Object.keys(WorkOrderMilestoneName),
    },
    bookingId: {
      name: 'Booking ID',
      type: EsDataType.KEYWORD,
      default: true,
    },
    brokerId: {
      name: 'Broker Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    brokerName: {
      name: 'Broker Name',
      type: EsDataType.TEXT,
    },
    loadType: {
      name: 'Load Type',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(LoadType),
    },
    badge: {
      name: 'Badge',
      type: EsDataType.KEYWORD,
      default: false,
      optionValues: Object.keys(WorkOrderBadge),
    },
    modeOfTransport: {
      name: 'Mode of Transportation',
      type: EsDataType.KEYWORD,
      default: false,
      optionValues: Object.keys(ModeOfTransport),
    },
    blockedReason: {
      name: 'Blocked Reason Code',
      type: EsDataType.KEYWORD,
      default: false,
      optionValues: Object.keys(WorkOrderBlockedReasonCode),
    },
    completedTime: {
      name: 'Completed Time',
      type: EsDataType.TIME,
      hidden: true,
    },
    completedById: {
      name: 'Completed By ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    completedByName: {
      name: 'Completed By Name',
      type: EsDataType.TEXT,
      hidden: true,
    },
    ...sharedCancellationColumns,
    isException: {
      name: 'Exception Task',
      type: EsDataType.BOOLEAN,
      hidden: true,
    },
    escalatedToId: {
      name: 'Have Been Escalated To ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    escalatedToName: {
      name: 'Have Been Escalated To',
      type: EsDataType.TEXT,
      hidden: true,
    },
    currentlyEscalatedToId: {
      name: 'Currently Escalated To ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    currentlyEscalatedToName: {
      name: 'Escalated To',
      type: EsDataType.TEXT,
      default: true,
    },
    currentlyEscalatedById: {
      name: 'Currently Escalated By ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    currentlyEscalatedByName: {
      name: 'Escalated By',
      type: EsDataType.TEXT,
      hidden: true,
    },
    lastEscalationTime: {
      name: 'Last Escalation Time',
      type: EsDataType.TIME,
      default: true,
    },
    lastResolutionTime: {
      name: 'Last Resolution Time',
      type: EsDataType.TIME,
      default: true,
    },
    entryNumber: {
      name: 'Entry #',
      type: EsDataType.TEXT,
      default: false,
    },
    lastRepliedById: {
      name: 'Currently replied By ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    lastRepliedByName: {
      name: 'Replied By Name',
      type: EsDataType.TEXT,
      hidden: true,
    },
    lastReplyTime: {
      name: 'Last Reply Time',
      type: EsDataType.TIME,
      default: true,
    },
    examSite: {
      name: 'Exam Site',
      type: EsDataType.KEYWORD,
      default: false,
    },
    examLspNotifiedDate: {
      name: 'LSP Notified Date',
      type: EsDataType.TIME,
      default: false,
    },
    examType: {
      name: 'Exam Type',
      type: EsDataType.KEYWORD,
      default: false,
    },
    examSubType: {
      name: 'Exam Sub Type',
      type: EsDataType.KEYWORD,
      default: false,
    },
    examStatus: {
      name: 'Exam Status',
      type: EsDataType.KEYWORD,
      default: false,
    },
  },
});
