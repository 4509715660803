import accounts from '@xbcb/tools/dist/accounts.json';
import devApigEvent from '@xbcb/tools/dist/devApigEvent';
import { Stage, SupportedCountryAbbreviation } from '@xbcb/shared-types';
process.env.AWS_NODEJS_CONNECTION_REUSE_ENABLED = '1';

export { Stage };
export enum AccountType {
  DEV = 'dev',
  PROD = 'prod',
}

export const Account: typeof accounts & { [key: string]: string } = accounts;
export const ApigEvent = devApigEvent;

export const app = {
  [Stage.ALPHA]: 'https://cbms-app-alpha.corp.amazon.com',
  [Stage.BRAVO]: 'https://cbms-app-alpha.corp.amazon.com', // Change this.
  [Stage.BETA]: 'https://cbms-app-beta.corp.amazon.com',
  [Stage.GAMMA]: 'https://cbms-app-gamma.corp.amazon.com',
  [Stage.PROD]: 'https://cbms.inlt.com',
};

export const importSign = {
  [Stage.ALPHA]: 'https://alpha.importsign.agl.amazon.dev/',
  [Stage.BRAVO]: 'https://alpha.importsign.agl.amazon.dev/', // Change this.
  [Stage.BETA]: 'https://beta.importsign.agl.amazon.dev',
  [Stage.GAMMA]: 'https://gamma.importsign.agl.amazon.dev',
  [Stage.PROD]: 'https://cbms.importsign.com',
};

export const newAppUI = {
  [Stage.ALPHA]: ['https://cbms-app-alpha.corp.amazon.com'],
  [Stage.BRAVO]: ['https://cbms-app-alpha.corp.amazon.com'], // Change this.
  [Stage.BETA]: ['https://cbms-app-beta.corp.amazon.com'],
  [Stage.GAMMA]: ['https://cbms-app-gamma.corp.amazon.com'],
  [Stage.PROD]: ['https://cbms.inlt.com', 'https://app.inlt.com'],
};

export const midwayNewAppUI = {
  [Stage.ALPHA]: ['https://alpha.cbms.global-mile.amazon.dev'],
  [Stage.BRAVO]: ['https://beta.cbms.global-mile.amazon.dev'],
  [Stage.BETA]: ['https://beta.cbms.global-mile.amazon.dev'],
  [Stage.GAMMA]: ['https://gamma.cbms.global-mile.amazon.dev'],
};

export const rumDomains: Record<Stage, string> = {
  [Stage.ALPHA]: 'cbms-app-alpha.corp.amazon.com',
  [Stage.BRAVO]: 'cbms-app-alpha.corp.amazon.com',
  [Stage.BETA]: 'cbms-app-beta.corp.amazon.com',
  [Stage.GAMMA]: 'cbms-app-gamma.corp.amazon.com',
  [Stage.PROD]: 'cbms.inlt.com',
};

export const mapStageToAccountType = (stage: Stage) => {
  return stage === Stage.PROD ? AccountType.PROD : AccountType.DEV;
};

export const stripAccountType = (accountName: string) => {
  const dev = '-dev';
  const prod = '-prod';
  if (accountName.endsWith(dev)) {
    return accountName.slice(0, -dev.length);
  } else if (accountName.endsWith(prod)) {
    return accountName.slice(0, -prod.length);
  } else {
    return accountName;
  }
};

/**
 * parses application level environmental variables
 * @return {object}
 */
export const getEnv = () => {
  const stage = (process.env.STAGE ?? Stage.ALPHA) as Stage; // default to alpha if not set
  let { APP_NAME: appName } = process.env; // set in AppStack in @xbcb/cdk, and passed via @xbcb/lambda-construct or to ApplicationLoadBalancedFargate
  const { FUNCTION_NAME: functionName } = process.env; // set in @xbcb/lambda-construct
  if (process.env.NODE_ENV === 'test') appName = 'test-app';
  const accountType = mapStageToAccountType(stage);
  const prodAwsIotEndpoint = 'a3oi9dpgao7ldn-ats.iot.us-west-2.amazonaws.com';
  const devAwsIotEndpoint = 'a349zdibcnisx6-ats.iot.us-west-2.amazonaws.com';
  const isProd = accountType === AccountType.PROD;
  return {
    stage,
    accountType,
    appName,
    functionName,
    awsIotEndpoint: isProd ? prodAwsIotEndpoint : devAwsIotEndpoint,
  };
};

/**
 * Checks if the code is running on Lambda or not.
 * @return {boolean} if lambda or not.
 */
export const isLambdaEnvironment = (): boolean => {
  const executionEnv = process.env.AWS_EXECUTION_ENV;
  return executionEnv ? executionEnv.startsWith('AWS_Lambda_nodejs') : false;
};

export const isLambdaNode20Environment = (): boolean => {
  const executionEnv = process.env.AWS_EXECUTION_ENV;
  return executionEnv ? executionEnv === 'AWS_Lambda_nodejs20.x' : false;
};

export const isCodeBuildEnvironment = (): boolean => {
  const buildId = process.env.CODEBUILD_BUILD_ID;
  return buildId !== undefined;
};

// Should be a dev stage (non-prod) as we should not be connecting to prod
// directly from localhost
export const isLocalHostInternalWebsite = (baseUrl: string) =>
  getEnv().stage !== Stage.PROD && baseUrl === 'https://localhost:3000';

export const isInternalWebsite = (baseUrl?: string) => {
  if (!baseUrl) return false;
  const { stage } = getEnv();
  if (isLocalHostInternalWebsite(baseUrl)) return true;
  // TODO also add marketing and documentation (update tests to include them)
  return (
    newAppUI[stage].some((url) => url === baseUrl) ||
    baseUrl === importSign[stage]
  );
};

export const secondaryLocalStages = [Stage.BRAVO];

export const localStages = [Stage.ALPHA, ...secondaryLocalStages];

export const getCountryAbbreviationFromCustomsBrokerId = (
  customsBrokerId: string,
) => {
  const countryAbrreviationLength = 2;
  const abrreviatedCountryCode = customsBrokerId
    .slice(0, countryAbrreviationLength)
    .toUpperCase();

  const countryCode =
    SupportedCountryAbbreviation[
      abrreviatedCountryCode as keyof typeof SupportedCountryAbbreviation
    ];

  if (countryCode) {
    return countryCode;
  }
  return undefined;
};
