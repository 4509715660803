export const addressListFragment = `fragment addressListFields on AddressV2 {
  country
  countrySubDivision
  countrySubDivisionName
  houseNumber
  houseNumberAddition
  line
  postCode
  postOfficeBox
  streetName
  type
}`;

export const commonFacilityFragment = `fragment commonFacilityFields on Facility {
    name
    addressList {
      ...addressListFields
    }
    duns
  }${addressListFragment}`;

export const supplierFragment = `fragment supplierFields on Supplier {
  addressList {
    ...addressListFields
  }
  alternateName
  changelogs {
    id
    version
  }
  description
  documents {
    id
    version
    documentTags
  }
  duns
  email
  id
  version
  identifiers {
    identification
    identificationType
  }
  memos {
    id
    tags {
      key
      value
    }
    text
    version
  }
  expirationTime
  tags{
    key
    value
  }
}${addressListFragment}`;

const importerEntityFields = `{
    id
    version
    operator {
        id
    }
    created {
        client {
            id
        }
    }
    countryCode
    activations {
        rejectionReasons
        externalBrokerReference {
            id
        }
        brokerRepresentation
        customsBroker {
            id
        }
        status
        rejected {
            time
        }
        activated {
            time
        }
        externalIorReferences {
            id
            referenceType
            documents {
                documentTag
                externalReference {
                    id
                    version
                    externalReferenceType
                }
            }
        }
    }
    documents {
        id
        documentTags
        tags {
            key
            value
        }
    }
}`;

export const importerEntityFragment = `fragment importerEntityFields on ImporterEntity ${importerEntityFields}`;

export const importerFragment = `fragment importerFields on Importer {
  addressList {
    ...addressListFields
  }
  alternateName
  changelogs {
    id
    version
  }
  description
  documents {
    id
    version
    documentTags
  }
  duns
  email
  id
  version
  identifiers {
    identification
    identificationType
  } 
  importerEntities {
    nodes {
      ...importerEntityFields
    }
  }
  memos {
    id
    tags {
      key
      value
    }
    text
    version
  }
  expirationTime
  tags{
    key
    value
  }
}${addressListFragment}
${importerEntityFragment}`;
