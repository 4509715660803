import gql from 'graphql-tag';
import {
  recordFields,
  recordActionFields,
  addressFields,
  costFields,
  phoneFields,
  externalComplianceReferenceFields,
} from '@xbcb/shared-queries';
import {
  pointOfContactFields,
  continuousBondsFields,
  usContinuousCustomsBondFragment,
} from '@xbcb/party-queries';
import { pgaRequirementFragments, type86PgaRequirementFragments } from './pga';

export const iorNumberFields = `fragment iorNumberFields on UsIorNumber {
  type
  value
}`;

export const passportFields = `fragment passportFields on Passport {
  number
  expiration
  countryCode
  type
}`;

export const masterBillFields = `fragment masterBillFields on MasterBill {
  number 
    houseBills {
      number
    }
}`;

export const entrySummaryPointOfContactFields = `
{
  name
  email
  phone {
    country
    number
    extension
  }
}
`;

export const usConsumptionEntryPointOfContactFields = `fragment usConsumptionEntryPointOfContactFields on UsConsumptionEntryPointOfContact ${entrySummaryPointOfContactFields}`;

export const usPostSummaryCorrectionPointOfContactFields = `fragment usPostSummaryCorrectionPointOfContactFields on UsPostSummaryCorrectionPointOfContact ${entrySummaryPointOfContactFields}`;

// TODO
// export const workOrderFragment = `fragment workOrderFields on WorkOrder {

// }`;

// export const workOrderFragments = `
// ${workOrderFragment}
// `;

export const usIsfFragment = `
fragment usIsfFields on UsIsf {
  ...recordFields
  status
  loadType
  isAutoSetReady
  group {
    id
    workOrders {
      id
      status
    }
    shipment {
      id
    }
  }
  operator {
    id
  }
  isfNumber 
  milestones {
    name
  }
  manufacturers {
    products {
      htsNumber 
      originCountryCode
      product {
        id
        version
      }
    } 
    name
    address {
      ...addressFields
    }
    duns
    supplier {
      id
      version
    }
  } 
  ... on UsIsf {
    masterBills {
      number
      billMatchStatus
      houseBills {
        number
        billMatchStatus
      }
    }
  }
  ior {
    iorNumber {
      ...iorNumberFields
    } 
    name
    passport { 
    ...passportFields
    }
    usIor {
      id
      version
    }
  }
  portOfUnladingCode
  placeOfDelivery
  singleTransactionBond {
    suretyCode 
    referenceNumber
  } 
  stuffingLocation {
    name
    address {
      ...addressFields
    }
    duns
    stuffingLocation {
      ... on Supplier {
        id
        version
      }
      ... on Facility {
        id
        version
      }
    }
  } 
  consolidator {
    name
    address {
      ...addressFields
    }
    duns
    consolidator {
      ... on Supplier {
        id
        version
      }
      ... on Facility {
        id
        version
      }
    }
  } 
  shipTo {
    name
    address {
      ...addressFields
    }
    duns
    firmsCode
    shipTo {
      id
      version
    }
  } 
  buyer {
    name
    address {
      ...addressFields
    }
    duns 
    ein
    sameAsIor
    buyer {
      id
      version
    }
  } 
  sellers {
    name
    address {
      ...addressFields
    }
    duns
    ein
    seller {
      id
      version
    }
  } 
  consignee {
    isIndividual
    iorNumber {
      ...iorNumberFields
    }
    sameAsIor
    usConsignee {
      id
      version
    }
  } 
  bookingParty {
    name
    address {
      ...addressFields
    }
    duns
    bookingParty {
      ... on Supplier {
        id
        version
      }
      ... on Forwarder {
        id
        version
      }
    }
  }
  cbpStatus
  isfType
  shipmentTypeCode
  broker {
    usCustomsBroker {
      id
    }
  }
  informal {
    estimatedValue {
      value
      currency
    }
    estimatedQuantity {
      value
      unit
    }
    estimatedWeight {
      value
      unit
    }
  }
}
`;
export const usIsfFragments = `
${usIsfFragment}
${recordFields}
${recordActionFields}
${addressFields}
${passportFields}
${iorNumberFields}
`;

export const dailyStatementFragment = `fragment dailyStatementFields on UsCbpEntryDailyStatement {
  printDate
  authorizationTime
  preliminaryGeneratedTime
  finalGeneratedTime
  number
}`;

export const periodicMonthlyStatementFragment = `fragment periodicMonthlyStatementFields on UsCbpEntryPeriodicMonthlyStatement {
  month
  preliminaryGeneratedTime
  finalGeneratedTime
  number
}`;

export const shipperContainerFragment = `fragment shipperContainerFields on ShippingContainer {
  number
  seal
  type
  weight
  quantity
  description
  length
  pgaType
}`;

export const usConsumptionEntryFragment = `fragment usConsumptionEntryFields on UsConsumptionEntry {
  ...usConsumptionEntryExclCommInvoicesFields
  ...usConsumptionEntryCommInvoicesFields
}`;

export const usConsumptionEntryExclCommInvoicesFragment = `fragment usConsumptionEntryExclCommInvoicesFields on UsConsumptionEntry {
  ...recordFields
  canceled{
    ...recordActionFields
  }
  operator {
    id
  }
  ior {
    name
    address {
      ...addressFields
    }
    pointOfContact {
      ...usConsumptionEntryPointOfContactFields
    }
    iorNumber {
      ...iorNumberFields
    }
    payerUnitNumber
    unknownDuns
    duns
    pmsStatus
    preferredPaymentMethod
    usIor {
      id
      version
      name
      pmsStatus
      paymentTerms
      iorNumber {
        value
        type
      }
      addresses {
        physical {
          ...addressFields
        }
        mailing {
          ...addressFields
        }
      }
    }
  }
  consignee {
    name
    address {
      ...addressFields
    }
    pointOfContact {
      ...usConsumptionEntryPointOfContactFields
    }
    iorNumber {
      ...iorNumberFields
    }
    sameAsIor
    unknownDuns
    duns
    usConsignee {
      id
      version
    }
  }
  buyer {
    ein
    name
    address {
      ...addressFields
    }
    sameAsIor
    buyer {
      id
      version
    }
  }
  cbpCharges {
    duties
    taxes
    fees
    adDuties
    cvDuties
    taxesList {
      type
      value
    }
    feesList {
      type
      value
    }
    feeDetails {
      fee
      display
      classCode
    }
  }
  cbpLiquidatedCharges {
    date
    duties
    taxes
    fees
    adDuties
    cvDuties
    taxesList {
      type
      value
    }
    feesList {
      type
      value
    }
  }
  poNumbers
  summaryStatus
  totalInvoiceLineCount
  summaryRejectionReasons {
    name
    code
  }
  summaryNotification {
    type
    remarks
    cbpTeam
    cbpOfficer {
      name
    }
  }
  filingTimeliness
  censusHeaderStatus
  controlStatus
  releaseStatus 
  releaseDate
  releaseDispositions {
    billNumber
    code
  }
  releaseRejectionReasons {
    name
    code
  }
  pgaDispositions {
    status
    code
  }
  milestones {
    id
    name
  }
  splitShipmentReleaseMethod
  cbpTeam
  collectionStatus
  collectionDate
  paymentType
  merchandiseUse
  dailyStatement {
    ...dailyStatementFields
  }
  periodicMonthlyStatement {
    ...periodicMonthlyStatementFields
  }
  entryNumber
  brokerNumber
  entryType
  singleTransactionBond {
    amount
    accountNumber
    suretyCode
  }
  masterBills {
    number
    houseBills {
      number
      quantity
      nonAMS
      inBondNumber
    }
  }
  containers {
    ...shipperContainerFields
  }
  departure {
    exportDate
    exportCountryCode
    portOfLadingCode
  }
  conveyance {
    modeOfTransport
    containerized
    conveyanceName
    tripNumber
    grossWeight
  }
  arrival {
    portOfUnladingCode
    firmsCode
    examSite
    preferredExamSite
    importDate
    usDestinationStateCode
    goNumber
    inBond {
      portOfEntryCode
      estimatedEntryDate
      initiationDate
    }
  }
  broker {
    name
    address {
      ...addressFields
    }
    pointOfContact {
      ...usConsumptionEntryPointOfContactFields
    }
    usCustomsBroker {
      id
      filerCode
      name
      addresses {
        mailing {
          ...addressFields
        }
        physical {
          ...addressFields
        }
      }
      phone {
        country
        number
        extension
      }
      pointOfContact {
        ...pointOfContactFields
      }
    }
  }
  badges
  loadType
  isAutoSetReady
  group {
    shipper {
       id
    }
    shipment {
      id
      clientIdentifier
      friendlyId
      legs {
        transportationServiceName
      }
      preferredDutyCollectPartyType
    }
    invoices{
      id
      number
      status
      lines{
        type
        amount{
          value
          currency
        }
      }
    }
    forwarders {
      forwarder {
        id
        tags {
          key
          value
        }
      }
    }
    id
  }
  tasks { id version status definition { workOrderTaskType } }
  tags {
    key 
    value
  }
  stateMachine {
    id
    version
    states { taskDefinition { workOrderTaskType id version } }
  }
  totalValue
  totalAssist
}`;

export const usConsumptionEntryCommInvoicesFragment = `fragment usConsumptionEntryCommInvoicesFields on UsConsumptionEntry {
  invoicesReference {
    downloadLink
    referenceKey
  }
  invoices {
    invoiceNumber
    value {
      ...costFields
    }
    assist {
      ...costFields
    }
    currencyRate
    adjustments {
      type
      value {
        value
        currency
      }
      description
    }
    seller {
      name
      address {
        ...addressFields
      }
      mid
      supplier {
        id
        version
      }
    }
    products {
      quantity
      totalValue {
        ...costFields
      }
      totalAssist {
        ...costFields
      }
      product {
        id
        version
      }
      lines {
        externalReference {
          ...externalComplianceReferenceFields
        }
        value
        assist
        description
        ruling {
          type
          number
        }
        poNumber
        spi
        licenseCode
        licenseNumber
        steelExclusionNumber
        aluminumExclusionNumber
        aluminumRequirements {
          aluminumPrimaryCountryOfSmelt
          aluminumSecondaryCountryOfSmelt
          aluminumCountryOfCast
          secondaryAluminumOnly
        }
        productClaimCode
        origin {
          countryCode
          stateCode
        }
        adCaseNumber
        cvCaseNumber
        feeOptions {
          required
          disclaim
          type
        }
        taxOptions {
          required
          disclaim
          type
          defer
          rate
        }
        cbpCharges {
          duties
          taxes
          fees
          adDuties
          cvDuties
          feeDetails {
            fee
            display
            classCode
          }
        }
        manufacturer {
          related
          name
          address {
            ...addressFields
          }
          mid
          supplier {
            id
            version
          }
        }
        tariffs {
          unitValue {
            value
            currency
          }
          unitAssist {
            value
            currency
          }
          unitReportingQuantities {
            value
            unit
          }
          pga {
            ...pgaRequirementFields
          }
          pgaDescription {
            sameAsCbp
            text
          }
          htsNumber
          hts {
            id
            version
          }
          extraPgaFlags
        }
        adCase {
          id
          version
          caseNumber
          rates {
            effectiveDate
            addedDate
            inactivatedDate
            adValorem
            specific
            uom
            uomDescription
          }
        }
        adDisclaimerCode
        cvCase {
          id
          version
          caseNumber
          rates {
            effectiveDate
            addedDate
            inactivatedDate
            adValorem
            specific
            uom
            uomDescription
          }
        }
        cvDisclaimerCode
        isNrbsOnFile
        nrbsRecordNumber
        censusWarnings {
          warningCode
          warningStatus
          overrideCode
        }
      }
    } 
  }
}
`;

export const usPostSummaryCorrectionFragment = `fragment usPostSummaryCorrectionFields on UsPostSummaryCorrection {
  ...recordFields
  acceleratedLiquidation
  headerReasons
  explanation
  operator {
    id
  }
  ior {
    name
    address {
      ...addressFields
    }
    iorNumber {
      ...iorNumberFields
    }
    usIor {
      id
      version
      ${continuousBondsFields}
      iorNumber {
        ...iorNumberFields
      }
    }
  }
  consignee {
    name
    address {
      ...addressFields
    }
    iorNumber {
      ...iorNumberFields
    }
    sameAsIor
    usConsignee {
      id
      version
    }
  }
  cbpCharges {
    duties
    taxes
    fees
    adDuties
    cvDuties
    taxesList {
      type
      value
    }
    feesList {
      type
      value
    }
    feeDetails {
      fee
      display
      classCode
    }
  }
  poNumbers
  summaryStatus
  summaryRejectionReasons {
    name
    code
  }
  summaryNotification {
    type
    remarks
    cbpTeam
    cbpOfficer {
      name
    }
  }
  cbpTeam
  entryNumber
  brokerNumber
  entryType
  singleTransactionBond {
    amount
    accountNumber
    suretyCode
  }
  invoices {
    invoiceNumber
    value {
      ...costFields
    }
    assist {
      ...costFields
    }
    currencyRate
    adjustments {
      type
      value {
        value
        currency
      }
      description
    }
    seller {
      name
      address {
        ...addressFields
      }
      mid
      supplier {
        id
        version
      }
    }
    products {
      quantity
      totalValue {
        ...costFields
      }
      totalAssist {
        ...costFields
      }
      product {
        id
        version
      }
      lines {
        lineReasons
        externalReference {
          ...externalComplianceReferenceFields
        }
        value
        assist
        description
        ruling {
          type
          number
        }
        poNumber
        spi
        licenseCode
        licenseNumber
        steelExclusionNumber
        aluminumExclusionNumber
        aluminumRequirements {
          aluminumPrimaryCountryOfSmelt
          aluminumSecondaryCountryOfSmelt
          aluminumCountryOfCast
          secondaryAluminumOnly
        }
        productClaimCode
        origin {
          countryCode
          stateCode
        }
        adCaseNumber
        cvCaseNumber
        feeOptions {
          required
          disclaim
          type
        }
        taxOptions {
          required
          disclaim
          type
          defer
          rate
        }
        cbpCharges {
          duties
          taxes
          fees
          adDuties
          cvDuties
          feeDetails {
            fee
            display
            classCode
          }
        }
        manufacturer {
          name
          address {
            ...addressFields
          }
          mid
          supplier {
            id
            version
          }
          related
        }
        tariffs {
          unitValue {
            value
            currency
          }
          unitAssist {
            value
            currency
          }
          unitReportingQuantities {
            value
            unit
          }
          htsNumber
          hts {
            id
            version
          }
        }
        adCase {
          id
          version
        }
        adDisclaimerCode
        cvCase {
          id
          version
        }
        cvDisclaimerCode
      }
    } 
  }
  masterBills {
    number
    houseBills {
      number
      quantity
      inBondNumber
    }
  }
  departure {
    exportDate
    exportCountryCode
    portOfLadingCode
  }
  conveyance {
    modeOfTransport
    containerized
    conveyanceName
    tripNumber
    grossWeight
  }
  arrival {
    portOfUnladingCode
    importDate
    usDestinationStateCode
    inBond {
      portOfEntryCode
      estimatedEntryDate
      initiationDate
    }
  }
  broker {
    name
    address {
      ...addressFields
    }
    pointOfContact {
      ...usPostSummaryCorrectionPointOfContactFields
    }
    usCustomsBroker {
      id
      filerCode
      name
      addresses {
        mailing {
          ...addressFields
        }
        physical {
          ...addressFields
        }
      }
      phone {
        country
        number
        extension
      }
      pointOfContact {
        ...pointOfContactFields
      }
    }
  }
  group {
    shipper {
       id
    }
    invoices{
      id
      number
      status
      lines{
        type
        amount{
          value
          currency
        }
      }
    }
    id
  }
  stateMachine {
    id
    version
  }
  totalValue
  totalAssist
}`;

export const usConsumptionEntryFragments = `
${usConsumptionEntryFragment}
${usConsumptionEntryExclCommInvoicesFragment}
${usConsumptionEntryCommInvoicesFragment}
${recordFields}
${recordActionFields}
${addressFields}
${iorNumberFields}
${periodicMonthlyStatementFragment}
${dailyStatementFragment}
${shipperContainerFragment}
${usConsumptionEntryPointOfContactFields}
${phoneFields}
${pointOfContactFields}
${pgaRequirementFragments}
${costFields}
${externalComplianceReferenceFields}`;

export const usConsumptionEntryExclCommInvoicesFragments = `
${usConsumptionEntryExclCommInvoicesFragment}
${recordFields}
${recordActionFields}
${addressFields}
${iorNumberFields}
${periodicMonthlyStatementFragment}
${dailyStatementFragment}
${shipperContainerFragment}
${usConsumptionEntryPointOfContactFields}
${phoneFields}
${pointOfContactFields}`;

export const usConsumptionEntryCommInvoicesFragments = `
${usConsumptionEntryCommInvoicesFragment}
${addressFields}
${phoneFields}
${pointOfContactFields}
${pgaRequirementFragments}
${costFields}
${externalComplianceReferenceFields}`;

export const usPostSummaryCorrectionFragments = `
${usPostSummaryCorrectionFragment}
${recordFields}
${recordActionFields}
${addressFields}
${iorNumberFields}
${usPostSummaryCorrectionPointOfContactFields}
${phoneFields}
${pointOfContactFields}
${costFields}
${externalComplianceReferenceFields}
${usContinuousCustomsBondFragment}
`;

export const usDrawbackClaimFragment = `
fragment usDrawbackClaimFields on UsDrawbackClaim {
  ...recordFields
  group {
    id
  }
  operator {
    id
  }
  TFTEAExportDestroyArticles {
    articleDescriptionText
    billOfLadingCarrierCode
    countryOfUltimateDestination
    exportDestroyDate
    exportDestroyIndicator
    htsNumber
    importTrackingIdentificationNumbers
    nameOfExporterDestroyer
    noticeOfIntentIndicator
    quantity
    uniqueIdentifierNumber
    unitOfMeasureCode
  }
  bond {
    accountNumber
    amount
    bondType
    suretyCode
  }
  imports {
    classifications {
      quantities {
        allowableQuantity
        enteredValuePerUnit
        quantity
        subsitutedValuePerUnit
        unitOfMeasureCode
      }
      articleDescriptionText
      htsNumber
    }
    revenuesClaimed {
      accountingClassCode
      calculatedAmount
      claimAmount
    }
    actionIndicator
    cbpEntrySummaryLineNumber
    drawbackAccountingMethodCode
    drawbackEligible
    entryNumber
    importTrackingIdentificationNumber
  }
  acceleratedPayment
  bondWaiverIndicator
  bondWaiverReasonCode
  entryNumber
  brokerNumber
  cbpRejectionReasons {
    code
    name
  }
  cbpStatus
  claimant {
    ...iorNumberFields
  }
  drawbackFilingPortCode
  drawbackProvision
  oneTimeWaiverIndicator
  waiverPriorNotice
}
`;

export const usDrawbackClaimFragments = `
${usDrawbackClaimFragment}
${recordFields}
${recordActionFields}
${iorNumberFields}
`;

export const deliveryOrderTruckerFragment = `fragment deliveryOrderTruckerFields on DeliveryOrderTrucker {
  trucker {
    id
    version
    name
  }
  dispatchEmail
  dispatchPhone {
    country
    number
    extension
  }
}`;

export const deliveryOrderPickupFragment = `fragment deliveryOrderPickupFields on DeliveryOrderPickup {
  firmsCode
  lastFreeDate
  pickupNumber
  specialInstructions
}`;

export const deliveryOrderOptionalEmailFragment = `fragment deliveryOrderOptionalEmailFields on DeliveryOrderEmailInfo {
  attachPackingList
  ccEmails
  ccParties {
    id
    name
    email
  }
  sendEmail
  subject
}`;

export const deliveryOrderDestinationFragment = `fragment deliveryOrderDestinationFields on DeliveryOrderDestination {
  location {
    addresses {
      mailing {
        address
        city
        postalCode
        stateCode
        countryCode
      }
      physical {
        address
        city
        postalCode
        stateCode
        countryCode
      }
    }
  }
  name
  receivingEmail
  receivingPhone {
    country
    number
    extension
  }
}
`;

export const deliveryOrderContainerFragment = `fragment deliveryOrderContainerFields on ShippingContainer {
  number
  seal
  type
  weight
  quantity
  description
}`;

export const deliveryOrderFragment = `fragment deliveryOrderFields on DeliveryOrder {
  ...recordFields
  group {
    id
    shipper {
      id
    }
    forwarders {
      forwarder {
        id
        tags {
          key
          value
        }
      }
    }
  }
  operator {
    id
  }
  status
  deadline
  billTo {
    id
  }
  milestones {
    name
    occurenceTime
  }
  status
  containers {
    ...deliveryOrderContainerFields
  }
  destination {
    ...deliveryOrderDestinationFields
  }
  email {
    ...deliveryOrderOptionalEmailFields
  }
  
  pickup {
    ...deliveryOrderPickupFields
  }

  trucker {
    ...deliveryOrderTruckerFields
  }
 } `;

export const deliveryOrderFragments = `
${deliveryOrderFragment}
${deliveryOrderContainerFragment}
${deliveryOrderTruckerFragment}
${deliveryOrderPickupFragment}
${deliveryOrderOptionalEmailFragment}
${deliveryOrderDestinationFragment}
${recordFields}
${recordActionFields}
`;

export const queryAms = gql`
  mutation QueryAms($idempotencyKey: String!, $input: QueryAmsInput!) {
    queryAms(idempotencyKey: $idempotencyKey, input: $input) {
      transaction {
        id
        version
      }
    }
  }
`;

export const usType86EntryFragment = `fragment usType86EntryFields on UsType86Entry {
  ...recordFields
  operator {
    id
  }
  ior {
    name
    address {
      ...addressFields
    }
    pointOfContact {
      ...usType86EntryPointOfContactFields
    }
    iorNumber {
      ...iorNumberFields
    }
    usIor {
      id
      version
    }
  }
  consignee {
    name
    address {
      ...addressFields
    }
    pointOfContact {
      ...usType86EntryPointOfContactFields
    }
    iorNumber {
      ...iorNumberFields
    }
    sameAsIor
    usConsignee {
      id
      version
    }
  }
  buyer {
    ein
    name
    address {
      ...addressFields
    }
    sameAsIor
    buyer {
      id
      version
    }
  }
  filingTimeliness
  censusHeaderStatus
  controlStatus
  releaseStatus 
  releaseDate
  releaseDispositions {
    billNumber
    code
  }
  releaseRejectionReasons {
    name
    code
  }
  pgaDispositions {
    status
    code
  }
  splitShipmentReleaseMethod
  cbpTeam
  collectionStatus
  entryNumber
  brokerNumber
  entryType
  invoices {
    invoiceNumber
    value {
      ...costFields
    }
    assist {
      ...costFields
    }
    currencyRate
    seller {
      name
      address {
        ...addressFields
      }
      mid
      supplier {
        id
        version
      }
    }
    products {
      quantity
      totalValue {
        ...costFields
      }
      totalAssist {
        ...costFields
      }
      product {
        id
        version
      }
      lines {
        adDisclaimerCode
        cvDisclaimerCode
        feeOptions { type disclaim }
        taxOptions { type disclaim }
        externalReference {
          ...externalComplianceReferenceFields
        }
        value
        assist
        description
        ruling {
          type
          number
        }
        poNumber
        spi
        licenseCode
        licenseNumber
        steelExclusionNumber
        aluminumExclusionNumber
        aluminumRequirements {
          aluminumPrimaryCountryOfSmelt
          aluminumSecondaryCountryOfSmelt
          aluminumCountryOfCast
          secondaryAluminumOnly
        }
        productClaimCode
        origin {
          countryCode
          stateCode
        }
        manufacturer {
          name
          address {
            ...addressFields
          }
          mid
          supplier {
            id
            version
          }
        }
        tariffs {
          unitValue {
            value
            currency
          }
          unitAssist {
            value
            currency
          }
          unitReportingQuantities {
            value
            unit
          }
          pga {
            ...type86PgaRequirementFields
          }
          pgaDescription {
            sameAsCbp
            text
          }
          htsNumber
          hts {
            id
            version
          }
          extraPgaFlags
        }
      }
    } 
  }
  masterBills {
    number
    billOnFile
    houseBills {
      number
      quantity
      inBondNumber
    }
  }
  containers {
    ...shipperContainerFields
  }
  departure {
    exportDate
    exportCountryCode
    portOfLadingCode
  }
  conveyance {
    modeOfTransport
    containerized
    conveyanceName
    tripNumber
    grossWeight
  }
  arrival {
    portOfUnladingCode
    importDate
    usDestinationStateCode
    inBond {
      portOfEntryCode
      estimatedEntryDate
      initiationDate
    }
    firmsCode
    preferredExamSite
  }
  broker {
    name
    address {
      ...addressFields
    }
    pointOfContact {
      ...usType86EntryPointOfContactFields
    }
    usCustomsBroker {
      id
      filerCode
      name
      addresses {
        mailing {
          ...addressFields
        }
        physical {
          ...addressFields
        }
      }
      phone {
        country
        number
        extension
      }
      pointOfContact {
        ...pointOfContactFields
      }
    }
  }
  loadType
  isAutoSetReady
  group {
    shipment {
      legs {
        transportationServiceName
      }
      preferredDutyCollectPartyType
    }
    invoices{
      id
      number
      status
      lines{
        type
        amount{
          value
          currency
        }
      }
    }
    id
  }
  tasks {
    id
    version
    status definition { workOrderTaskType }
    workOrder {
      id
      stateMachine { workOrderType }
      ... on UsType86Entry {
        conveyance { modeOfTransport }
        masterBills { number billOnFile houseBills { number } }
      }
      ... on UsConsumptionEntry {
        conveyance { modeOfTransport }
        masterBills { number houseBills { number } }
      }
    }
  }
  stateMachine {
    id
    version
    states { taskDefinition { workOrderTaskType id version } }
  }
  totalValue
  totalAssist
}`;

export const usType86EntryPointOfContactFields = `fragment usType86EntryPointOfContactFields on UsType86EntryPointOfContact ${entrySummaryPointOfContactFields}`;

export const usType86EntryFragments = `
${usType86EntryFragment}
${recordFields}
${recordActionFields}
${addressFields}
${iorNumberFields}
${shipperContainerFragment}
${usType86EntryPointOfContactFields}
${phoneFields}
${pointOfContactFields}
${costFields}
${type86PgaRequirementFragments}
${externalComplianceReferenceFields}`;
