import { capitalCase } from 'change-case';
import { DocumentTag } from '@xbcb/document-types';

// internal lib used to format the document tag
export const formatDocumentTag = (documentTag: DocumentTag): string => {
  // Check for special cases first (where we want to capitalize abbreviations)
  if (documentTag === DocumentTag.EIN_VERIFICATION) return 'EIN Verification';
  if (documentTag === DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID)
    return 'Government Issued Photo ID';
  if (documentTag === DocumentTag.BUSINESS_LICENSE_OR_PERMIT)
    return 'Business License Or Permit';
  if (documentTag === DocumentTag.PASSPORT_DL_GOVT_ISSUED_ID)
    return 'Passport DL Govt Issued ID';

  if (documentTag === DocumentTag.FREE_ENTRY_OF_RETURNED_AMERICAN_PROD)
    return 'Free Entry Of Returned American Prod';

  // Keep these word capitalized
  const preserveWords = [
    'ISF',
    'MSDS',
    'CBPF',
    'CBMA',
    'INLT',
    'IOR',
    'FDA',
    'PSC',
  ];
  for (const word of preserveWords) {
    const index = documentTag.indexOf(word);

    if (index > -1)
      return `${capitalCase(documentTag.slice(0, index))} ${word} ${capitalCase(
        documentTag.slice(index + word.length),
      )}`.trim();
  }

  // Otherwise default to capitalCase
  const formattedDocumentTag = capitalCase(documentTag);

  // Check for POA
  const powerOfAttorney = capitalCase('POWER_OF_ATTORNEY');
  if (formattedDocumentTag.includes(powerOfAttorney)) {
    return formattedDocumentTag.replace(powerOfAttorney, 'POA');
  }

  return capitalCase(documentTag);
};
