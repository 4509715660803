// this is shared by the frontend and backend, THINK before you import
import { EsDataType } from './enums';
import {
  AccountType,
  ObjectType,
  RecordType,
  RecordInterfaceType,
  SortOrder,
} from '@xbcb/shared-types';
import { get } from 'lodash';
import {
  shipment,
  document,
  usIsf,
  usIor,
  deIor,
  gbIor,
  nlIor,
  frIor,
  usConsumptionEntry,
  usDrawbackClaim,
  usInBond,
  workOrder,
  workOrderGroup,
  workOrderTask,
  trucker,
  supplier,
  facility,
  product,
  classification,
  forwarder,
  operator,
  customsAgent,
  usCustomsBroker,
  shipper,
  usConsignee,
  gbConsignee,
  deConsignee,
  nlConsignee,
  frConsignee,
  invoice,
  invoiceLinesRequest,
  invoicePayment,
  documentTemplate,
  externalSystem,
  forwarderSystem,
  shipperSystem,
  user,
  forwarderUser,
  operatorUser,
  shipperUser,
  exchangeMessage,
  adCvdCase,
  usPrincipalAdCvdCase,
  hts,
  usHts,
  usIorContinuousBondRequest,
  usIorActivation,
  usPostSummaryCorrection,
  reportReconciliation,
  stampDocumentSignRequest,
  templateDocumentSignRequest,
  memo,
  tableFilter,
  globalSearch,
  operatorSystem,
  customDomain,
  workOrderStateMachine,
  bulkUpload,
  onboardingRequest,
  deliveryOrder,
  customsBroker,
  ukCustomsBroker,
  deCustomsBroker,
  nlCustomsBroker,
  frCustomsBroker,
  broker,
  customsEntry,
  gbCustomsEntry,
  deCustomsEntry,
  nlCustomsEntry,
  frCustomsEntry,
  payableInvoice,
  usType86Entry,
  gbIorActivation,
  deIorActivation,
  nlIorActivation,
  frIorActivation,
  party,
  invoiceCreditNote,
  assignmentTeam,
  subjectMatterExpertTeam,
  team,
  processingError,
  errorDefinition,
  businessSupportTeam,
  importer,
  importerEntity,
  importerEntityActivation,
  workOrderTaskEscalationMessage,
  globalConsignee,
} from './objectTypes';

export interface Column {
  default?: boolean | AccountType[];
  name?: string | ((accountType: AccountType) => string);
  react?: boolean;
  type?: EsDataType;
  format?: Function;
  whitelist?: AccountType[];
  blacklist?: AccountType[];
  hidden?: boolean; // set true if the field should not be included in the UI tables
  analyzer?: string; // if not passed, the default analyzer for the type will be used, e.g. 'whitespace' for 'keyword', 'standard' for 'text'
  optionValues?: string[];
}

export interface Columns {
  [columnName: string]: Column;
}

export type TableOptions = {
  pageSize?: number;
  current?: number;
  sortOrder?: SortOrder;
  sortField?: string;
  records?: string[];
  filters?: {};
  whitelist?: string[];
  blacklist?: string[];
  dependentFields?: string[];
  recordTypes?: RecordType[];
};

export interface RecordColumns extends TableOptions {
  columns: Columns;
}

export const createdByName = {
  name: 'Created By',
  type: EsDataType.TEXT,
};

export const recordColumns = {
  [RecordType.DOCUMENT]: document,
  [RecordType.STAMP_DOCUMENT_SIGN_REQUEST]: stampDocumentSignRequest,
  [RecordType.TEMPLATE_DOCUMENT_SIGN_REQUEST]: templateDocumentSignRequest,
  [RecordType.DOCUMENT_TEMPLATE]: documentTemplate,
  [RecordType.US_IOR]: usIor,
  [RecordType.DE_IOR]: deIor,
  [RecordType.GB_IOR]: gbIor,
  [RecordType.NL_IOR]: nlIor,
  [RecordType.FR_IOR]: frIor,
  [RecordType.US_ISF]: usIsf,
  [RecordType.US_CONSUMPTION_ENTRY]: usConsumptionEntry,
  [RecordType.US_DRAWBACK_CLAIM]: usDrawbackClaim,
  [RecordType.US_IN_BOND]: usInBond,
  [RecordInterfaceType.WORK_ORDER]: workOrder,
  [RecordType.WORK_ORDER_GROUP]: workOrderGroup,
  [RecordType.WORK_ORDER_TASK]: workOrderTask,
  [RecordType.WORK_ORDER_TASK_ESCALATION_MESSAGE]:
    workOrderTaskEscalationMessage,
  [RecordType.SHIPMENT]: shipment,
  [RecordType.TRUCKER]: trucker,
  [RecordType.SUPPLIER]: supplier,
  [RecordType.FACILITY]: facility,
  [RecordType.PRODUCT]: product,
  [RecordType.CLASSIFICATION]: classification,
  [RecordType.OPERATOR]: operator,
  [RecordType.CUSTOMS_AGENT]: customsAgent,
  [RecordInterfaceType.CUSTOMS_BROKER]: customsBroker,
  [RecordType.US_CUSTOMS_BROKER]: usCustomsBroker,
  [RecordType.UK_CUSTOMS_BROKER]: ukCustomsBroker,
  [RecordType.DE_CUSTOMS_BROKER]: deCustomsBroker,
  [RecordType.NL_CUSTOMS_BROKER]: nlCustomsBroker,
  [RecordType.FR_CUSTOMS_BROKER]: frCustomsBroker,
  [RecordType.BROKER]: broker,
  [RecordType.FORWARDER]: forwarder,
  [RecordType.SHIPPER]: shipper,
  [RecordType.US_CONSIGNEE]: usConsignee,
  [RecordType.GB_CONSIGNEE]: gbConsignee,
  [RecordType.DE_CONSIGNEE]: deConsignee,
  [RecordType.NL_CONSIGNEE]: nlConsignee,
  [RecordType.FR_CONSIGNEE]: frConsignee,
  [RecordType.INVOICE_LINES_REQUEST]: invoiceLinesRequest,
  [RecordType.INVOICE]: invoice,
  [RecordType.INVOICE_PAYMENT]: invoicePayment,
  [RecordType.INVOICE_CREDIT_NOTE]: invoiceCreditNote,
  [RecordType.PAYABLE_INVOICE]: payableInvoice,
  [RecordType.REPORT_RECONCILIATION_REQUEST]: reportReconciliation,
  [RecordInterfaceType.EXTERNAL_SYSTEM]: externalSystem,
  [RecordType.FORWARDER_SYSTEM]: forwarderSystem,
  [RecordType.SHIPPER_SYSTEM]: shipperSystem,
  [RecordInterfaceType.USER]: user,
  [RecordType.FORWARDER_USER]: forwarderUser,
  [RecordType.SHIPPER_USER]: shipperUser,
  [RecordType.OPERATOR_USER]: operatorUser,
  [RecordType.EXCHANGE_MESSAGE]: exchangeMessage,
  [RecordType.AD_CVD_CASE]: adCvdCase,
  [RecordType.US_PRINCIPAL_AD_CVD_CASE]: usPrincipalAdCvdCase,
  [RecordType.HTS]: hts,
  [RecordType.US_HTS]: usHts,
  [RecordType.US_IOR_CONTINUOUS_BOND_REQUEST]: usIorContinuousBondRequest,
  [RecordType.MEMO]: memo,
  [RecordType.TABLE_FILTER]: tableFilter,
  [RecordType.US_IOR_ACTIVATION]: usIorActivation,
  [RecordType.US_POST_SUMMARY_CORRECTION]: usPostSummaryCorrection,
  [RecordType.OPERATOR_SYSTEM]: operatorSystem,
  [RecordType.CUSTOM_DOMAIN]: customDomain,
  [RecordType.TEAM]: team,
  [RecordType.ASSIGNMENT_TEAM]: assignmentTeam,
  [RecordType.BUSINESS_SUPPORT_TEAM]: businessSupportTeam,
  [RecordType.SUBJECT_MATTER_EXPERT_TEAM]: subjectMatterExpertTeam,
  [RecordType.WORK_ORDER_TASK_DEFINITION]: workOrderTask, // The table config is same as workOrderTask because the search criteria for task definition is  `searchCriteria: WorkOrderTaskSearchCriteriaInput`
  [RecordType.WORK_ORDER_STATE_MACHINE]: workOrderStateMachine,
  [RecordType.BULK_MILESTONE_UPLOAD_REQUEST]: bulkUpload,
  [RecordType.BULK_CHARGES_UPLOAD_REQUEST]: bulkUpload,
  [RecordType.BULK_COMPLIANCE_UPLOAD_REQUEST]: bulkUpload,
  [RecordType.ONBOARDING_REQUEST]: onboardingRequest,
  [RecordType.DELIVERY_ORDER]: deliveryOrder,
  [RecordInterfaceType.CUSTOMS_ENTRY]: customsEntry,
  [RecordType.GB_CUSTOMS_ENTRY]: gbCustomsEntry,
  [RecordType.DE_CUSTOMS_ENTRY]: deCustomsEntry,
  [RecordType.NL_CUSTOMS_ENTRY]: nlCustomsEntry,
  [RecordType.FR_CUSTOMS_ENTRY]: frCustomsEntry,
  [RecordType.US_TYPE86_ENTRY]: usType86Entry,
  [RecordType.GB_IOR_ACTIVATION]: gbIorActivation,
  [RecordType.DE_IOR_ACTIVATION]: deIorActivation,
  [RecordType.NL_IOR_ACTIVATION]: nlIorActivation,
  [RecordType.FR_IOR_ACTIVATION]: frIorActivation,
  [RecordInterfaceType.PARTY]: party,
  [RecordType.PROCESSING_ERROR]: processingError,
  [RecordType.ERROR_DEFINITION]: errorDefinition,
  [RecordType.IMPORTER]: importer,
  [RecordType.IMPORTER_ENTITY]: importerEntity,
  [RecordType.IMPORTER_ENTITY_ACTIVATION]: importerEntityActivation,
  [RecordType.GLOBAL_CONSIGNEE]: globalConsignee,
};

export const tableRecordColumns = {
  GLOBAL_SEARCH: globalSearch,
};

const allowTable = ({
  blacklist,
  whitelist,
  accountType,
}: {
  blacklist?: string[];
  whitelist?: string[];
  accountType: AccountType;
}) =>
  (!blacklist && !whitelist) ||
  (!whitelist && !blacklist?.includes(accountType)) ||
  (!blacklist && whitelist?.includes(accountType));

type fieldNames =
  | 'default'
  | 'name'
  | 'react'
  | 'type'
  | 'format'
  | 'whitelist'
  | 'blacklist';

export const defaultTable = ({
  accountType,
  objectType,
}: {
  accountType: AccountType;
  objectType: ObjectType | 'GLOBAL_SEARCH';
}) => {
  const objColumns = get({ ...recordColumns, ...tableRecordColumns }, [
    objectType,
    'columns',
  ]);
  const cols = Object.keys(objColumns).filter((fieldName) => {
    const obj = objColumns[fieldName] || {};
    const blacklist = get(obj, 'blacklist');
    const whitelist = get(obj, 'whitelist');
    if (
      !obj.hidden &&
      obj.default &&
      allowTable({ blacklist, whitelist, accountType })
    )
      return true;
    return false;
  });
  return {
    ...(recordColumns[objectType as keyof typeof recordColumns] ||
      tableRecordColumns[objectType as keyof typeof tableRecordColumns]),
    columns: cols,
  };
};

export const defaultTables = (accountType: AccountType) => {
  const stringObj: { [key: string]: any } = {};
  return Object.keys({ ...recordColumns, ...tableRecordColumns }).reduce(
    (acc, objectType) => {
      acc[objectType] = defaultTable({
        accountType,
        objectType: objectType as ObjectType,
      });
      return acc;
    },
    stringObj,
  );
};

export const getColumnOptions = ({
  objectType,
  accountType,
}: {
  objectType: ObjectType | 'GLOBAL_SEARCH';
  accountType: AccountType;
}) => {
  const objColumns = get(
    { ...recordColumns, ...tableRecordColumns },
    [objectType, 'columns'],
    {},
  );
  return Object.keys(objColumns).filter((columnName) => {
    const obj = objColumns[columnName] || {};
    const whitelist = get(obj, 'whitelist');
    const blacklist = get(obj, 'blacklist');
    return !obj.hidden && allowTable({ blacklist, whitelist, accountType });
  });
};

export const getColumn = ({
  column,
  objectType,
}: {
  column: string;
  objectType: ObjectType | 'GLOBAL_SEARCH';
}) =>
  get(
    { ...recordColumns, ...tableRecordColumns },
    [objectType, 'columns', column],
    {},
  );

export const getColumnValue = ({
  column,
  objectType,
  value,
}: {
  column: string;
  objectType: ObjectType | 'GLOBAL_SEARCH';
  value: string;
}) =>
  get({ ...recordColumns, ...tableRecordColumns }, [
    objectType,
    'columns',
    column,
    value,
  ]);

export const getColumnName = ({
  column,
  objectType,
  accountType,
}: {
  column: string;
  objectType: ObjectType | 'GLOBAL_SEARCH';
  accountType?: AccountType;
}): string => {
  let name = getColumnValue({ column, objectType, value: 'name' });
  if (typeof name === 'function') {
    name = name(accountType);
  }
  return name;
};
