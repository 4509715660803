/**
 * Partners or parties representing the sender or receiver of the message.
 */
export enum MessagingPartner {
  INLT = 'INLT', // INLT would be the source for outgoing and destination for incoming messages.
  EMAIL = 'EMAIL', // Messages to sent to sent to email exchange server.
  US_CBP = 'US_CBP', // United States Customs Border Protection.
  GTN_NEXUS = 'GTN_NEXUS', // GTN Nexus
  CHAIN_IO = 'CHAIN_IO', // Chain.io is a third party company that handles all of our TMS integrations
  HMRC = 'HMRC', // His Majesty's Revenue and Customs (UK)
  OPS_INBOX = 'OPS_INBOX', // Email received at an operator inbox that is forwarded to inlt.io (INLT as the receiver) for processing and display on Inbox UI
}
