import { calculateDynamoDBItemSize } from './dynamoDBSizeCalculator';
import log from '@xbcb/log';

export const MAX_ALLOWED_KILOBYTES = 370; // With some buffer over DDB Item size limitation of 400KB

/**
 * A util to identify if an object is larger than MAX_ALLOWED_KILOBYTES
 * @param {unknown} obj the object to check the size of
 * @return {boolean}
 */
export const isObjectTooLarge = (obj: any): boolean => {
  try {
    if (!obj) return false;
    const sizeInKb = calculateDynamoDBItemSize(obj) / 1024;
    log.info(`Object size calculated as : ${sizeInKb} KB`);
    return sizeInKb >= MAX_ALLOWED_KILOBYTES;
  } catch (error) {
    throw new Error(`Unable to calculate item size, error : ${error}`);
  }
};
