import { AccountType } from '@xbcb/shared-types';
import { operatorId, EsDataType } from '../../index';
import { createUserTeamTableConfig } from './userTeam';

export const assignmentTeam = createUserTeamTableConfig({
  columns: {
    operatorId,
    operatorName: {
      name: 'Operator',
      type: EsDataType.TEXT,
      whitelist: [AccountType.OPERATOR],
    },
    customsBrokerId: {
      name: 'Broker ID',
      type: EsDataType.KEYWORD,
      hidden: true,
      whitelist: [AccountType.OPERATOR],
    },
    customsBrokerName: {
      name: 'Broker Name',
      type: EsDataType.TEXT,
      whitelist: [AccountType.OPERATOR],
    },
    workOrderType: {
      name: 'Work Order Type',
      type: EsDataType.KEYWORD,
      default: true,
    },
    workOrderTaskType: {
      name: 'Work Order Task Type',
      type: EsDataType.KEYWORD,
      default: true,
    },
    modeOfTransport: {
      name: 'Mode of Transport',
      type: EsDataType.KEYWORD,
      default: true,
    },
    loadType: {
      name: 'Load Type',
      type: EsDataType.KEYWORD,
      default: true,
    },
    productType: {
      name: 'Product Type',
      type: EsDataType.KEYWORD,
      default: true,
    },
    forwarderId: {
      name: 'Forwarder ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    forwarderName: {
      name: 'Forwarder',
      type: EsDataType.TEXT,
      default: true,
    },
    iorId: {
      name: 'Ior ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    iorName: {
      name: 'Ior',
      type: EsDataType.TEXT,
      hidden: true,
    },
    shipperId: {
      name: 'Shipper ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    shipperName: {
      name: 'Shipper',
      type: EsDataType.TEXT,
      hidden: true,
    },
    memberCount: {
      name: 'Members',
      type: EsDataType.INTEGER,
      default: true,
    },
    subjectMatterExpertTeamId: {
      name: 'SME Team ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    subjectMatterExpertTeamName: {
      name: 'SME Team',
      type: EsDataType.TEXT,
      default: true,
    },
    backupTeamId: {
      name: 'Backup Team ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    backupTeamName: {
      name: 'Supporting Team',
      type: EsDataType.TEXT,
      default: true,
    },
  },
});
